import './commons';
import '@/scss/elro.scss';

// JS specifics to Elro goes here
import ModalLangSwitcher from './js/ModalLangSwitcher';
import MobileMenu from './js/MobileMenu';
import ProductImagesSideTexts from './js/ProductImagesSideTexts';

new ModalLangSwitcher().init();
new MobileMenu().init('elro');
new ProductImagesSideTexts().init();
