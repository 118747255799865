const ProductImagesSideTexts = class {
  init() {
    this.toggleContentBlock();
  }

  toggleContentBlock() {
    const contentBlocks = document.querySelectorAll(
      '.product-images-side-texts'
    );

    if (contentBlocks.length === 0) {
      return;
    }
    contentBlocks.forEach((contentBlock) => {
      const contentBlockTitle = contentBlock.querySelector(
        '.product-images-side-texts__title'
      );
      const contentBlockButton = contentBlock.querySelector(
        '.product-images-side-texts__title-button-dropdown'
      );
      const contentBlockContent = contentBlock.querySelector(
        '.product-images-side-texts__wrapper'
      );

      if (contentBlockButton === null) {
        return;
      }
      contentBlockTitle.addEventListener('click', () => {
        contentBlockButton.classList.toggle(
          'product-images-side-texts__title-button-dropdown--active'
        );
        contentBlockContent.classList.toggle(
          'product-images-side-texts__wrapper--active'
        );
      });
    });
  }
};

export default ProductImagesSideTexts;
