class ModalLangSwitcher {
  init() {
    this.initModalLangSwitcher();
    this.initSelectCountry();
  }

  initModalLangSwitcher() {
    let langSwitcherButton = document.querySelector('.lang-switcher');
    let modalWrapper = document.querySelector('.modal-lang-switcher__wrapper');
    let closeButton = modalWrapper.querySelector('.modal-lang-switcher__close');
    if (langSwitcherButton && modalWrapper) {
      this.openModal(modalWrapper, langSwitcherButton);
    }
    if (modalWrapper && closeButton) {
      this.closeModal(modalWrapper, closeButton);
    }
  }

  closeModal(modalWrapper, closeButton) {
    closeButton.addEventListener('click', () => {
      if (
        modalWrapper.classList.contains('modal-lang-switcher__wrapper--active')
      ) {
        modalWrapper.classList.remove('modal-lang-switcher__wrapper--active');
      }
    });

    modalWrapper.addEventListener('click', (e) => {
      e.stopPropagation();
      if (e.target.closest('#inside-wrapper') === null) {
        modalWrapper.classList.remove('modal-lang-switcher__wrapper--active');
      }
    });
  }

  openModal(modalWrapper, langSwitcherButton) {
    langSwitcherButton.addEventListener('click', () => {
      modalWrapper.classList.add('modal-lang-switcher__wrapper--active');
    });
  }

  initSelectCountry() {
    let selects = document.querySelectorAll('.input-select-countries');
    selects.forEach((select) => {
      let optionsContainer = select.querySelector(
        '.input-select-countries__options'
      );
      let iconToggle = select.querySelector('.input-select-countries__icon');
      let placeholder = select.querySelector(
        '.input-select-countries__placeholder'
      );
      let options = select.querySelectorAll('.input-select-countries__option');

      if (optionsContainer && iconToggle) {
        this.toggleOptions(optionsContainer, iconToggle);
        if (options && placeholder) {
          this.selectOption(iconToggle, optionsContainer, options, placeholder);
        }
      }

      this.getCurrentStoreCountry();
      this.getCurrentStoreView();
      this.redirectToStoreView();
    });
    this.resetSelectedLangIfCountryChanged();
    this.authorizeConfirmButton();
  }

  toggleOptions(optionsContainer, iconToggle) {
    iconToggle.addEventListener('click', () => {
      if (iconToggle.classList.contains('icon--angle-up')) {
        iconToggle.classList.remove('icon--angle-up');
        iconToggle.classList.add('icon--angle-down');
        optionsContainer.classList.remove(
          'input-select-countries__options--shown'
        );
      } else {
        iconToggle.classList.add('icon--angle-up');
        iconToggle.classList.remove('icon--angle-down');
        optionsContainer.classList.add(
          'input-select-countries__options--shown'
        );
      }
    });
  }

  selectOption(iconToggle, optionsContainer, options, placeholder) {
    options.forEach((option) => {
      option.addEventListener('click', () => {
        placeholder.innerHTML = option.innerHTML;

        placeholder.setAttribute(
          'data-country-selected-value',
          option.getAttribute('data-store-code-value')
        );

        if (option.getAttribute('data-storeview-url-value') !== null) {
          placeholder.setAttribute(
            'href',
            option.getAttribute('data-storeview-url-value')
          );
        }
        this.getCurrentStoreCountry();

        iconToggle.classList.remove('icon--angle-up');
        iconToggle.classList.add('icon--angle-down');
        optionsContainer.classList.remove(
          'input-select-countries__options--shown'
        );
      });
    });
  }

  getCurrentStoreCountry() {
    const countryPlaceholder = document.querySelector(
      '.input-select-countries__placeholder-country'
    );
    if (
      countryPlaceholder.getAttribute('data-country-selected-value') !== null &&
      countryPlaceholder
    ) {
      this.displayLangOptionsBySelectedCountry(
        countryPlaceholder.getAttribute('data-country-selected-value')
      );
    }
  }

  getCurrentStoreView() {
    const langPlaceholder = document.querySelector(
      '.input-select-countries__placeholder-language'
    );
    this.displayCurrentLang(langPlaceholder);
  }

  displayCurrentLang(langPlaceholder) {
    const allLangOptions = document.querySelectorAll(
      '.input-select-countries[data-select-value="language"] .input-select-countries__option'
    );

    allLangOptions.forEach((langOption) => {
      if (
        langOption.getAttribute('data-storeview-code-value') ===
        langPlaceholder.getAttribute('data-storeview-selected-value')
      ) {
        langPlaceholder.innerHTML = langOption.innerHTML;
      }
    });
  }

  displayLangOptionsBySelectedCountry(selectedCountryValue) {
    const arraySelectedCountryValue = selectedCountryValue.split('_');
    const needle =
      arraySelectedCountryValue[arraySelectedCountryValue.length - 1];
    const allLangOptions = document.querySelectorAll(
      '.input-select-countries[data-select-value="language"] .input-select-countries__option'
    );

    allLangOptions.forEach((langOption) => {
      if (
        langOption.getAttribute('data-storeview-code-value').includes(needle)
      ) {
        langOption.classList.remove('input-select-countries__option--hidden');
      } else {
        langOption.classList.add('input-select-countries__option--hidden');
      }
    });
  }

  redirectToStoreView() {
    const submitButton = document.querySelector('.modal-lang-switcher__button');
    submitButton.addEventListener('click', () => {
      const selectedLang = document.querySelector(
        '.input-select-countries__placeholder-language'
      );
      const redirectUrl = selectedLang.getAttribute('href');
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    });
  }

  resetSelectedLangIfCountryChanged() {
    const countryPlaceholder = document.querySelector(
      'p.input-select-countries__placeholder-country'
    );
    const langPlaceholder = document.querySelector(
      'p.input-select-countries__placeholder-language'
    );

    const observer = new MutationObserver(function (mutationsList) {
      mutationsList.forEach(function (mutation) {
        if (mutation.type === 'attributes') {
          langPlaceholder.setAttribute('data-storeview-selected-value', null);
          langPlaceholder.setAttribute('data-country-selected-value', null);
          langPlaceholder.setAttribute('href', '');
          langPlaceholder.innerHTML = langPlaceholder.getAttribute(
            'data-placeholder-value'
          );
        }
      });
    });

    const config = { attributes: true, subtree: true };
    observer.observe(countryPlaceholder, config);
  }

  authorizeConfirmButton() {
    const submitButton = document.querySelector('.modal-lang-switcher__button');
    const langPlaceholder = document.querySelector(
      '.input-select-countries__placeholder-language'
    );

    const observer = new MutationObserver(function (mutationsList) {
      mutationsList.forEach(function (mutation) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'href'
        ) {
          if (langPlaceholder.getAttribute('href') !== '') {
            submitButton.classList.remove(
              'modal-lang-switcher__button--disabled'
            );
          } else {
            submitButton.classList.add('modal-lang-switcher__button--disabled');
          }
        }
      });
    });
    const config = { attributes: true, subtree: true };
    observer.observe(langPlaceholder, config);
  }
}

export default ModalLangSwitcher;
